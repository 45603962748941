<template>
  <date-pick
    v-model="selectedDate"
    :hasInputElement="false"
    v-bind="{
      mobileBreakpointWidth: 768,
      format: 'YYYY-MM-DD',
      weekdays: weekdays,
      months: months
    }"
  ></date-pick>
</template>

<script>
const DatePick = () => import("vue-date-pick");
import "vue-date-pick/dist/vueDatePick.css";
import { format } from "date-fns";

export default {
  name: "DefaultDatePicker",
  mixins: [],
  props: {
    unique: {
      type: [String, Number]
    }
  },
  components: { DatePick },
  data() {
    return {
      selectedDate: format(new Date(), "Y-MM-dd"),
      weekdays: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
      months: [
        "Январь, ",
        "Февраль, ",
        "Март, ",
        "Апрель, ",
        "Май, ",
        "Июнь, ",
        "Июль, ",
        "Август, ",
        "Сентябрь, ",
        "Октябрь, ",
        "Ноябрь, ",
        "Декабрь, "
      ]
    };
  },
  mounted() {
    this.$root.$on(`dateChild:change_${this.unique}`, (value) => {
      this.selectedDate = value;
    });
    this.Change__Calendar__Date();
  },
  methods: {
    Change__Calendar__Date() {
      this.value = this.selectedDate;
      let data = {
        eventName: "Change__Calendar__Date",
        data: {
          value: this.value,
          unique: this.unique
        }
      };
    }
  },
  computed: {},
  watch: {
    selectedDate() {
      this.Change__Calendar__Date();
    }
  },
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/breakpoints/breakpoints";
/deep/ {
  .vdpOuterWrap {
    color: $color-text-primary-2;
    .vdpInnerWrap {
      width: 100%;
      min-width: 234px;
      max-width: 234px;

      box-shadow: none;
      border: none;
      border-radius: 10px;

      .vdpHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-white;

        .vdpArrow {
          &::before {
            display: none;
          }
        }

        .vdpArrowPrev {
          &::after {
            left: 70%;
            top: 38%;
            border: none;
            width: 2em;
            height: 2em;
            background-image: url(~@/assets/img/icons/arrowDataPickerPrev.svg);
            background-repeat: no-repeat;
          }
        }

        .vdpArrowNext {
          &::after {
            left: 35%;
            top: 38%;
            border: none;
            width: 2em;
            height: 2em;
            background-image: url(~@/assets/img/icons/arrowDataPickerNext.svg);
            background-repeat: no-repeat;
          }
        }

        .vdpPeriodControls {
          margin-bottom: 31px;
          .vdpPeriodControl {
            > button {
              margin-top: 23px;
              padding: 0;

              font-size: 13px;
              line-height: 13px;
              font-weight: 400;
              color: $color-text-primary-2;
            }

            > select {
              color: $color-text-primary-2;
              background-color: $color-elements-background;
              font-family: SF, sans-serif;
              font-size: 13px;
              border: 1px solid $color-contour;

              option {
                font-family: SF, sans-serif;
              }
            }
          }
        }
      }

      .vdpTable {
        thead {
          border: none;

          tr {
            th {
            }
          }

          .vdpHeadCell {
            padding: 0;

            .vdpHeadCellContent {
              font-size: 13px;
              line-height: 13px;
              font-weight: 400;
            }
          }
        }

        .vdpRow {
          .vdpCell {
            @media (hover: hover) {
              &:hover {
                .vdpCellContent {
                  background-color: $color-interactive;
                }
              }
            }

            &.today {
              .vdpCellContent {
                color: $color-text-primary-1;

                background-color: $color-charts-grey-2;

                @media (hover: hover) {
                  &:hover {
                    color: $color-white;
                  }
                }
              }
            }

            &.selected {
              &.today {
                .vdpCellContent {
                  color: $color-white;
                }
              }

              .vdpCellContent {
                background-color: $color-interactive;
              }
            }

            .vdpCellContent {
              width: 27px;
              height: 27px;
              display: flex;
              align-items: center;
              justify-content: center;

              font-size: 13px;
              line-height: 13px;
              font-weight: 400;

              @media (hover: hover) {
                &:hover {
                  background-color: $color-interactive;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
